import React from 'react';
import * as styles from './compani.module.scss';
import imgMarca from '../../../images/Home/companiMarca.png';

import logo1 from '../../../images/Home/logo1.png';
import logo2 from '../../../images/Home/logo2.png';
import logo3 from '../../../images/Home/logo3.png';
import logo4 from '../../../images/Home/logo4.png';
import logo5 from '../../../images/Home/logo5.png';
import enecuatro from '../../../images/Landing/Logoenecuatro.svg';
import cabletel from '../../../images/Landing/cabletel-white.svg';
import toyota from '../../../images/Landing/Toyota-Logo.svg';

const CompaniSection = () => {
	return (
		<section className={styles.companiSection}>
            <div className={styles.containCompani}
               data-sal-delay='200'
               data-sal='slide-up'
               data-sal-duration='400'
               data-sal-easing='ease-out-back'>
                <div className={styles.containImg}>
                    <img src={imgMarca} alt='imagen de numero uno'></img>
                </div>
                <div className={styles.containTitle}>
                    <p>Nuestra experiencia nos respalda. </p>
                    <p>Hace mas de 5 años que digitalizamos procesos para Startups, Pymes y grandes empresas. </p>
                </div>
                <div className={styles.containLogoCompani}>
                    <div className={styles.containLogo}>
                        <img src={logo5} alt='logo wecover'className={styles.wecover} />
                    </div>
                    <div className={styles.containLogo}>
                        <img src={cabletel} alt='logo cabletel' className={styles.cabletel}/>
                    </div>
                    <div className={styles.containLogo}>
                        <img src={logo4} alt='logo tcfa' className={styles.tcfa}/>
                    </div>
                    <div className={styles.containLogo}>
                        <img src={toyota} alt='logo toyota' className={styles.toyota}/>
                    </div>
                    <div className={styles.containLogo}>
                        <img src={logo1} alt='logo lenovo' className={styles.lenovo}/>
                    </div>
                    
                </div>
                <div className={styles.containLogoCompani}>
                    <div className={styles.containLogo}>
                        <img src={enecuatro} alt='logo enecuatro' className={styles.enecuatro}/>
                    </div>
                    <div className={styles.containLogo}>
                        <img src={logo3} alt='logo bera' className={styles.bera}/>
                    </div>    
                    <div className={styles.containLogo}>
                        <img src={logo2} alt='logo lexus' className={styles.lexus}/>
                    </div>
                </div>
            </div>
		</section>
	);
};
export default CompaniSection;