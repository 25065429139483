import React from 'react';
import * as styles from './benefits.module.scss';
import imgMedicine from '../../../images/Benefits/medicine-ic.svg';
import imgHappy from '../../../images/Benefits/happy-developers-ic.svg';
import imgBreakfast from '../../../images/Benefits/breakfast-ic.svg';
import cancel from '../../../images/Landing/cancel.svg';
import clock from '../../../images/Landing/clock.svg';
import diagram from '../../../images/Landing/diagram.svg';
import like from '../../../images/Landing/like.svg';


const Benefits = () => {
	return (
		<section className={styles.bodyBenefits}>
			<div className={styles.contenerTitle} >
				<div className={styles.title}>
					<h2 
					data-sal-delay='450'
					data-sal='slide-left'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'>Disfruta de beneficios de un software a medida</h2>
				</div>
			</div>
			<div className={styles.contentBenefits}>
				<div className={styles.benefits}>
					<div className={styles.Benefitschild}>
						<div className={styles.benefit}>
							<img src={clock} alt="optimizacion de tiempo"></img>
							<h4>Optimización<br></br>de tiempo</h4>
						</div>
						<div className={styles.benefit}>
							<img src={cancel} alt="imagen de dias de homeworking"></img>
							<h4>Eliminación<br></br>de error humano</h4>
						</div>
					</div>
					<div className={styles.centerBenefitschild}>
						<div className={styles.benefit}>
							<img src={imgMedicine} alt="imagen de medicina de primer nivel"></img>
							<h4>Mejora<br></br>procesos</h4>
						</div>
						<div className={styles.benefit}>
							<img className={styles.bigImage} src={imgHappy} alt="imagen de excelente ambiente laboral"></img>
							<h4 className={styles.BenefitCenterText}>Fácil<br></br>uso</h4>
						</div>
						<div className={styles.benefit}>
							<img src={imgBreakfast} alt="imagen de desayunos y meriendas"></img>
							<h4>Disminución del<br></br>uso de papeles</h4>
						</div>
					</div>
					<div className={styles.Benefitschild}>
						<div className={styles.benefit}>
							<img src={like} alt="imagen de capacitación permanente"></img>
							<h4>Eliminación de<br></br>tiempo ocioso</h4>
						</div>
						<div className={styles.benefit}>
							<img src={diagram}alt="imagen de snacks, bebidas y frutas"></img>
							<h4>Posibilidad<br></br>de crecimiento</h4>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}


export default Benefits ;