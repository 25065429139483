import React from "react";
import Layout from "../components/UI/layout";
import Header from "../components/UI/Header/header";
import SEO from "../components/UI/seo";
import Benefits from "../components/Landing/Benefits/benefits";
import ProcessSection from "../components/Landing/Process/process";
import CompaniSection from "../components/Landing/Compani/compani";
import Help from "../components/Landing/Contact/contact"



const IndexPage = () => (
    <Layout>
      <SEO title="Codeicus | Landing" />
      <Header
        title="Es hora de dejar excel"
        description={['Animate a dar el salto.','Cuando tu negocio esta creciendo, la mejor solución es contar con un software a medida que responda de forma efectiva a todas las necesidades de tu empresa.']}
      />
      <Benefits/>
      <ProcessSection/>
      <CompaniSection/>
      <Help/>
    </Layout>
  )
  
  export default IndexPage