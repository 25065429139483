// extracted by mini-css-extract-plugin
export var bera = "compani-module--bera--cc58d";
export var cabletel = "compani-module--cabletel--6cf7f";
export var companiSection = "compani-module--companiSection--dfbeb";
export var containCompani = "compani-module--containCompani--529b4";
export var containImg = "compani-module--containImg--0300b";
export var containLogo = "compani-module--containLogo--8ab95";
export var containLogoCompani = "compani-module--containLogoCompani--fd210";
export var containTitle = "compani-module--containTitle--278b1";
export var enecuatro = "compani-module--enecuatro--6e00b";
export var lenovo = "compani-module--lenovo--623c1";
export var lexus = "compani-module--lexus--ae64d";
export var tcfa = "compani-module--tcfa--332b2";
export var toyota = "compani-module--toyota--c87e7";
export var wecover = "compani-module--wecover--f9a16";