import React from 'react';
import * as styles from './process.module.scss';



const ProcessSection = () => {
	return (
		<section className={styles.processSection}>
            <div className={styles.containProcess}>
                <div className={styles.containTitle} 
                    data-sal-delay='500'
             		data-sal='zoom-in'
            		data-sal-duration='400'
            		data-sal-easing='ease-out-back'>
                    <h2>¿C&oacute;mo lo logramos?</h2>
                    <p>Aplicando una metodolog&iacute;a progresivas y en etapas</p>
                </div>
                <div className={styles.containBody}>
                    <div className={styles.step1}
                        data-sal-delay='400'
                        data-sal='slide-right'
                        data-sal-duration='500'
                        data-sal-easing='ease-out-back'>
                        <div className={styles.detalle}>
                            <div className={styles.number}>
                                <p>1</p>
                            </div>
                            <div className={styles.title}>
                                <h3>An&aacute;lisis y Definici&oacute;n</h3> 
                            </div>
                            <div className={styles.description}>
                                <p>Relevamos tus procesos, tecnolog&iacute;a y las necesidades existentes en la empresa </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.step3}                        
                        data-sal-delay='200'
                        data-sal='slide-up'
                        data-sal-duration='500'
                        data-sal-easing='ease-out-back'>
                        <div className={styles.detalle}>
                            <div className={styles.number}>
                                <p>3</p>
                            </div>
                            <div className={styles.title}>
                                <h3>Puesta en Marcha</h3> 
                            </div>
                            <div className={styles.description}>
                                <p>Implementamos el sistema y lo dejamos listo para usar</p>
                            </div>

                        </div>
                    </div>
                    <div className={styles.step2}                        
                        data-sal-delay='200'
                        data-sal='slide-left'
                        data-sal-duration='500'
                        data-sal-easing='ease-out-back'>
                        <div className={styles.detalle}>
                            <div className={styles.number}>
                                <p>2</p>
                            </div>
                            <div className={styles.title}>
                                <h3>Desarrollo e Implementaci&oacute;n</h3> 
                            </div>
                            <div className={styles.description}>
                                <p>Diseñamos flujos funcionales y desarrollamos un sistema interactivo y f&aacute;cil de gestionar</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
		</section>
	);
};
export default ProcessSection;
