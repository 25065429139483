// extracted by mini-css-extract-plugin
export var BenefitCenterText = "benefits-module--BenefitCenterText--8956b";
export var Benefitschild = "benefits-module--Benefitschild--f2fbd";
export var benefit = "benefits-module--benefit--a07d0";
export var benefits = "benefits-module--benefits--f1c36";
export var bigImage = "benefits-module--bigImage--95021";
export var bodyBenefits = "benefits-module--bodyBenefits--55e9a";
export var centerBenefitschild = "benefits-module--centerBenefitschild--a4c84";
export var contenerTitle = "benefits-module--contenerTitle--1ab36";
export var contentBenefits = "benefits-module--contentBenefits--1b40c";
export var title = "benefits-module--title--04496";