// extracted by mini-css-extract-plugin
export var bodyHelp = "contact-module--bodyHelp--fcf01";
export var btn = "contact-module--btn--9dcd5";
export var btnLottie = "contact-module--btnLottie--7ca0e";
export var contenerHelp = "contact-module--contenerHelp--ef85f";
export var detail = "contact-module--detail--385b7";
export var error = "contact-module--error--6cd92";
export var fromContacto = "contact-module--fromContacto--ac1f4";
export var fromLeft = "contact-module--fromLeft--4d50a";
export var fromRight = "contact-module--fromRight--7df1c";
export var textArea = "contact-module--textArea--d51a2";
export var textBox = "contact-module--textBox--3d0f5";