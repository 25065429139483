// extracted by mini-css-extract-plugin
export var containBody = "process-module--containBody--ff1dd";
export var containBtn = "process-module--containBtn--61674";
export var containProcess = "process-module--containProcess--036f6";
export var containTitle = "process-module--containTitle--27c28";
export var description = "process-module--description--48a29";
export var detalle = "process-module--detalle--2ad21";
export var number = "process-module--number--38b58";
export var processSection = "process-module--processSection--e07d1";
export var step1 = "process-module--step1--59230";
export var step2 = "process-module--step2--85490";
export var step3 = "process-module--step3--0ef51";
export var title = "process-module--title--13cad";